<template>
    <div @click="active_trip.trip_id ? redirectToActiveTripPage() : redirectToTripDetails()" class="trip-card radius6-round" :class="{activeTripStyle : active_trip.trip_id}">
        <div class="trip-info-top horizontal-padding-16 flex-row">
            <img style="width: 3%;" class="location-line" :src="location_line"/>

            <div style="width: 93%;">
                <div style="margin-left: 9px;" class="trip-info-top-right">
                    <div>
                        <div>
                            <img :src="bus_icon"/>
                            <span class="t-or-d" style='margin-left:6px ;'>Takeoff</span>
                        </div>
                        <div class="pickup-location-details flex-row-space-between">
                            <div class="td-name">
                                    {{active_trip.route.pickup}}
                            </div>
                            <div>
                                <div class="trip-begins">Trip Begins</div>
                                <div class="trip-begins-time">
                                    {{active_trip.itinerary.trip_time}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin-left: 9px; width: 100%;" class="trip-info-top-right">
                <div>
                    <div>
                        <img :src="bus_icon"/>
                        <span class="t-or-d" style="margin-left:6px ;">Destination</span>
                    </div>
                    <div class="pickup-location-details flex-row-space-between">
                        <div class="td-name">{{active_trip.route.destination}}</div>
                        <div>
                            <div class="trip-begins">
                                Est. Arrival
                            </div>
                            <div class="trip-begins-time">{{getETA(active_trip.itinerary.trip_time, active_trip.route.duration)}}</div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

        </div>

        <hr :class="{ hideSection : active_trip.trip_id}" class="rule">
        <div :class="{ hideSection : active_trip.trip_id}" class="flex-row-aacjjc trip-info-bottom horizontal-padding-16">
            <div>
                <DateTripTag :date="convertDate(active_trip.trip_date)"/>
                <PassengerTripTag :date="JSON.stringify(active_trip.total_passengers) + ' passengers'"/>
            </div>
            <span class="route-code">
                {{active_trip.route.route_code}}
            </span>
        </div>

        <!-- activates for active trip -->
        <div :class="{ hideBadge : !active_trip.trip_id}">
            <div class="trip-active-alert horizontal-padding-16">
                <div class="next-stop">
                    <img style="margin-right: 5px;" :src="next_stop_icon"/> Live trip
                </div>
                <div class="active-route-code" id="active-route-code">
                    {{active_trip.route.route_code}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import location_line from '../assets/icons/location-gradient.svg'
import bus_icon from '../assets/icons/bus.svg'
import DateTripTag from './DateTripTag.vue'
import PassengerTripTag from './PassengerTripTag.vue'
import next_stop_icon from '../assets/icons/next-stop-icon.svg'
import router from '../router'
import { mapActions } from 'vuex'
import moment from 'moment'
import ExclusiveBadge from './ExclusiveBadge.vue'

export default {
  name: 'ActiveTripCard',
  props: {
    active_trip: Object
  },
  data () {
    return {
      location_line: location_line,
      bus_icon: bus_icon,
      next_stop_icon: next_stop_icon,
      showBadge: 'showBadge',
      hideBadge: 'hideBadge'
    }
  },
  methods: {
    ...mapActions(['setTripToBook']),
    getETA (startTime, duration) {
      const endTime = moment(startTime, 'HH:mm').add(duration?.replace('mins', ''), 'minutes').format('LT')
      return endTime
    },
    redirectToTripDetails () {
      this.setClickedTrip(this.active_trip)
      router.push({
        name: 'TripDetail',
        params: {
          tripId: `${this.active_trip.id}`,
          routeId: `${this.active_trip.route_id}`,
          tripDate: `${this.active_trip.trip_date}`,
          driverId: `${this.active_trip.driver.id}`
        }
      })
    },
    setClickedTrip (trip) {
      this.setSelectedTrip(trip)
      this.putTripIdInLocalStorage(trip.trip_id)
    },
    putTripIdInLocalStorage (tripId) {
      localStorage.setItem('next_active_trip_id', tripId)
    },
    async setSelectedTrip (trip) {
      this.setTripToBook(trip)
    },
    redirectToActiveTripPage () {
      this.setClickedTrip(this.active_trip)
      router.push({
        name: 'ActiveTripPage',
        params: {
          routeId: `${this.active_trip.route_id}`,
          tripDate: `${this.active_trip.trip_date}`,
          driverId: `${this.active_trip.driver.id}`,
        }
      })
    },
    convertDate (dateString) {
      const arg = dateString.slice(0, 10)
      const newDate = new Date(arg).toDateString()
      const trip_date = `${newDate.slice(4, 8)} ${newDate.slice(8, 10)}, ${newDate.slice(11, 15)}`
      return trip_date
    }
  },
  components: { DateTripTag, PassengerTripTag, ExclusiveBadge }
}
</script>

<style lang="scss" scoped>

.trip-info-top {
  padding: 1rem;
}
    .next-stop{
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 20px;
        color: #FFFFFF;
    }
    .trip-active-alert{
        /* border-bottom-right-radius: 6px; */
        border-radius: 0px 0px 6px 6px;
        color: white;
    }
    .showBadge {
        display: block;
    }
    .hideBadge {
        display: none;
    }
    .hideSection{
        display: none;
    }

    .trip-active-alert{
        height: 46px;
        background: #003D36;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .trip-info-top-right{
        width: 100%;
        /* border: 1px solid red; */
    }
    .route-code, .route-code a{
        font-size: 13px;
        color: var(--shuttlers-green);
        font-weight: 500;
        text-decoration: none;
    }
    #route-code, .route-code a {
        color: #0DAC5C;
    }
    #active-route-code, .active-route-code a {
        color: #ffffff;
    }
    .trip-begins-time{

        /* Body Text/Small - 13px/Regular */

        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 0.81rem;
        line-height: 20px;
        /* identical to box height, or 154% */

        text-align: right;

        /* Shuttlers Neutrals/700 */
        color: #595E5C;
    }
    .trip-begins{

        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 0.69rem;
        line-height: 16px;
        /* identical to box height, or 145% */
        text-align: right;

        /* Shuttlers Neutrals/500 */
        color: #8D918F;
    }

    .td-name{
        /* 21 Road, Festac town, Lagos, Nigeria */
        width: 78%;
        /*height: 32px;*/

        font-family: 'Heebo';
        font-style: normal;
        font-weight: 500;
        font-size: 0.9rem;
        line-height: 16px;

        color: #000005;
        margin-right: auto;

    }
    .t-or-d{
        /* Body Text/Extra Small - 12px/Regular */
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 20px;

        /* Gray/Gray 5 */

        color: #6E717C;
    }

    .location-line{
        height: 5rem;
    }
    .pickup-location-details{
        width: 100%;
    }
    .rule{
        /* Gray / Gray 10 */

        border: 1px solid #EFF2F7;
    }
   .trip-card{
        width: 100%;
        height: auto;
        padding-bottom: 20px;
        background: #FFFFFF;
        /* Elevation 4 */

        box-shadow: 0px 2px 8px rgba(94, 94, 94, 0.12);
        margin-bottom: 20px;
        }

    .horizontal-padding-16{
        padding-left: 16px;
        padding-right: 16px;
    }
    .radius6-round {
        border-radius: 6px;
    }
    .activeTripStyle {
        padding-bottom: 0px;
    }
</style>
